<template>
  <div class="progress">
    <div class="select">
      <el-row>
        <el-col :span="6">
          分类：<el-select v-model="formData.select_level" placeholder="请选择">
            <el-option :label="'线上进度'" :value="3"> 线上进度 </el-option>
            <el-option :label="'线下进度'" :value="4"> 线下进度 </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          日期：<el-select v-model="formData.search_type" placeholder="请选择">
            <el-option :label="'今日'" :value="1"> 今日 </el-option>
            <el-option :label="'本月'" :value="2"> 本月 </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" v-if="formData.user_level == 1">
          小组：<el-select
            @visible-change="visibleChange"
            v-model="formData.group_id"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in group"
              :key="index"
              :label="item.group_name"
              :value="item.group_id"
            >
              {{ item.group_name }}
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="search">查询</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="width: 80%; margin: 0 auto">
      <ul class="li" style="color: #909399">
        <p>名字</p>
        <p>成功</p>
        <p>失败</p>
        <p v-if="formData.search_type == 1">进行中</p>
        <p v-if="formData.search_type == 2">成功率</p>
        <p>小组</p>
      </ul>
      <ul
        style="color: #606266"
        class="ul"
        v-for="(item, index) in tableData"
        :key="index"
      >
        <li class="li" v-for="(Citem, Cindex) in item" :key="Cindex">
          <p>{{ Citem.name }}</p>
          <p>{{ Citem.success_count }}</p>
          <p>{{ Citem.err_count }}</p>
          <p v-if="formData.search_type == 1">{{ Citem.loading_count }}</p>
          <p v-if="formData.search_type == 2">{{ Citem.success_rate }}</p>
          <p>{{Citem.group_name}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      formData: {
        user_id: JSON.parse(window.localStorage.getItem("user")).id,
        user_level: JSON.parse(window.localStorage.getItem("user")).level,
        search_type: 1,
        select_level: 3,
        group_id: "",
      },
      group: [],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getData();
  },

  methods: {
    async visibleChange(val) {
      if (val) {
        let data = {
          level: this.formData.user_level,
          user_id: this.formData.user_id,
        };
        let response = await this.$request.post("/showGroups", data);
        if (response.data.success) {
          this.group = response.data.data;
        } else {
          this.$message({
            message: response.data.msg,
            type: "error",
          });
        }
      }
    },
    async getData() {
      let _this = this;
      let response = await this.$request.post("/myTaskInfo", this.formData);
      this.tableData = response.data.res;
      let data = {
        name: "合计",
        success_count: null,
        err_count: null,
        loading_count: null,
        success_rate: "",
      };
      _this.tableData.map((item) => {
        item.map((Citem) => {
          data.success_count += Citem.success_count;
          data.err_count += Citem.err_count;
          data.loading_count += Citem.loading_count;
          var all_num = Citem.success_count + Citem.err_count;
          if (all_num == 0) {
            Citem.success_rate = "0.0%";
          } else {
            Citem.success_rate =
              (
                (Citem.success_count /
                  (Citem.success_count + Citem.err_count)) *
                100
              )
                .toFixed(2)
                .toString() + "%";
          }
        });
      });
      data.success_rate =
        ((data.success_count / (data.success_count + data.err_count)) * 100)
          .toFixed(2)
          .toString() + "%";
      _this.tableData.push([data]);
    },
    search() {
      this.getData();
    },
  },
};
</script>
<style lang='less' scoped>
.select {
  margin-bottom: 20px;
}
.ul:nth-child(odd) {
  background-color: antiquewhite;
}

.ul:nth-child(even) {
  background-color: #d7c9f3;
}

.ul:last-child {
  color: red;
}
.li {
  display: flex;
  align-items: center;
  height: 35px;
  margin-top: 1px;
  p {
    display: inline-block;
    flex: 1;
    text-align: center;
    border: 1px solid #ebeef5;
    margin: -1px -1px 0 0;
    height: 35px;
    line-height: 35px;
  }
}
</style>